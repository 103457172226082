.HomePage {
  height:           100vh;
  width:            100vw;
  display:          flex;
  -webkit-box-flex: 1;
  flex-grow:        1;
}

.left {
      -ms-flex-align: stretch;
    -ms-flex-direction: column;
    -ms-flex-negative: 0;
    -ms-flex-preferred-size: auto;
    -webkit-align-items: stretch; 
    -webkit-box-align: stretch; 
    -webkit-box-direction: normal; 
    -webkit-box-orient: vertical; 
    -webkit-flex-basis: auto; 
    -webkit-flex-direction: column; 
    -webkit-flex-shrink: 0; 
    align-items: stretch; 
    border: 0 solid black; 
    box-sizing: border-box;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-basis: auto; 
    flex-direction: column;
    flex-shrink: 0;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;
    min-height: 0px;
    min-width: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
    position: relative;
    z-index: 0;
}

.Navigation {
  width: 387px;
}



.NavigationContainer {
  width:                  150px;
  -webkit-box-direction:  normal;
  -webkit-box-orient:     vertical;
  flex-direction:         column;
  margin:                 10px auto;
}

.NavigationItemContainer {
  display:        flex;
  min-width:      0px;
}

.NavigationItem {
  display:          flex;
  text-decoration:  none;
  font-family:      system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif;
  font-size:        19px;
  border-radius:    100px;
  box-shadow:       none;
  cursor:           pointer;
  font-weight:      bold;
  line-height:      20px;
  padding:          6px 16px;
  position:         relative;
  text-align:       center;
  white-space:      nowrap;
}


.NavigationItem:hover {
  border-radius:    100px;
  background-color:   #234;
  color:              orange;
}

.NavIcon {
  
}

.NavLabel {
  
}