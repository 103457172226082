.Footer{
  border-top:       1px solid #e6ecf0;
  padding:          11px 0;
  position:         fixed;
  background-color: #fff;
  bottom:           -10%;
  width:            100%;
  z-index:          2;  
}

.FooterFixed {
  width:            100%;
  bottom:           0;
}

.FooterList {
  display:          flex;
  justify-content:  center;
  width:            auto;
}

@media screen and (min-width: 1236px) {
  .FooterList {
    width:          1190px;
  }
}

.FooterItem {
  display:          inline-block;
  padding:          0 9px 0 9px;
}

.FooterItem a {
  color:            #aab8c2;
  font-size:        12px;
}

.FooterCopyright {
  color:            #aab8c2;
  font-size:        12px;
}




