.LoginPage {
  position: relative;
}

.LoginFormContainer {
  width: 200px;
  margin: 200px auto;
}

.optionsBlock {
  display: flex;
  justify-content: space-between;
}

.dogContainer1 {
  position: fixed;
  width: 30vw;
  left: -508px;
  bottom: -120px;
  transform: scale(-1.0, 1.0);
  -webkit-animation-name: slideInDogo1; /* Safari 4.0 - 8.0 */
  -webkit-animation-delay: 4s;  /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 4s; /* Safari 4.0 - 8.0 */
  -webkit-animation-timing-function: linear;
  -webkit-animation-fill-mode: forwards;
  animation-name: slideInDogo1;
  animation-delay: 4s;
  animation-duration: 4s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}

@-webkit-keyframes slideInDogo1 {
  from {left: -508px;}
  to {left: 0px;}
}

@keyframes slideInDogo1 {
  from {left: -508px;}
  to {left: 0px;}
}


.dogContainer2top {
  position: fixed;
  width: 30vw;
  right:  -630px;
  bottom: 0px;
  transform: scale(1.5, 1.5);
  -webkit-animation-name: slideInDogo2; /* Safari 4.0 - 8.0 */
  -webkit-animation-delay: 10s;  /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 5s; /* Safari 4.0 - 8.0 */
  -webkit-animation-timing-function: linear;
  -webkit-animation-fill-mode: forwards;
  animation-name: slideInDogo2;
  animation-delay: 10s;
  animation-duration: 5s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}

.dogContainer2 {
  position: relative;
  left: 0px;
  -webkit-animation-name: slideInDogo3; /* Safari 4.0 - 8.0 */
  -webkit-animation-delay: 22s;  /* Safari 4.0 - 8.0 */
  -webkit-animation-duration: 7s; /* Safari 4.0 - 8.0 */
  -webkit-animation-timing-function: linear;
  -webkit-animation-fill-mode: forwards;
  animation-name: slideInDogo3;
  animation-delay: 22s;
  animation-duration: 7s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}

@-webkit-keyframes slideInDogo2 {
  from {right:  -630px;}
  to {right:  -450px;}
}

@keyframes slideInDogo2 {
  from {  right:  -630px;}
  to {right:  -450px;}
}

@-webkit-keyframes slideInDogo3 {
  from {right:  0px;}
  to {right:  300px;}
}

@keyframes slideInDogo3 {
  from {right:  0px;}
  to {left:  300px;}
}