body {
	margin: 0;
	
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #14171a;
	font-size: 14px;
	line-height: 20px;
	overflow-y: scroll;
	position: relative;
}

body {
    font-family: "Segoe UI",Arial,sans-serif;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
	monospace;
}

#root {
	width: 100vw;
	height: 100vh;
}