body {
  overflow:          hidden;  
}

.LandingPage {
  display:          flex;
  flex-direction:   column;
  background-color: #fff;
  min-height:        100%;
}

.LandingPageContent {
  display:          flex;
  flex-direction:   row-reverse;
  flex-grow:        1;
  
}

.LandingPageContentCell {
  -moz-box-align:   center;
  -ms-flex-align:   center;
  align-items:      center;
  display:          -moz-box;
  display:          -ms-flexbox;
  display:          flex;
  -moz-box-flex:    1;
  -ms-flex:         1;
  flex:             1;
  -moz-box-pack:    center;
  -ms-flex-pack:    center;
  justify-content:  center;
  position:         relative;
}

.LandingPageCommunicationBlock {
  display:          flex;
  flex:              1;
  align-items:      center;
  justify-content:  center;
  background-color:  #ffb944;
  overflow:          hidden;

}

.LandingPageCommunicationBlock .LogoIcon {
    max-width:      none;
    position:       absolute;
}


.LandingPage-CommunicationContent{
  color:            #fff;
  max-width:        440px;
  padding:          0 16px;
  position:         relative;
}



.LandingPage-CommunicationItem {
  display:          flex;
  font-size:        18px;
  font-weight:      bold;
  line-height:      24px;
  margin:           48px  auto;
}

.CommIcon {
  background:     transparent;
  display:        inline-block;
  font-style:     normal;
  vertical-align: baseline;
  position:       relative;
  margin:         -8px 16px 0px 0px !important;
}

.TinyIcon {
  width:            50px;
}

@media only screen and (max-width: 800px) {
  .LandingPageContent {
    display:          block;
    height:           100%;
  }
  
  .LandingPageCommunicationBlock {
    -moz-box-align:   center;
    -ms-flex-align:   center;
    align-items:      center;
    display:          -moz-box;
    display:          -ms-flexbox;
    display:          flex;
    -ms-flex-wrap:    wrap;
    flex-wrap:        wrap;
    margin-bottom:    0;
    padding-top:      0;
  }
  
  .LandingPageCommunicationBlock .LogoIcon {
    height:           150%;
    right:            -24%;
    top:              -30%;
  }
  
  .LandingPage .Footer {
    bottom:           auto;
    height:           100%;
    position:         static;
  }
}

@media only screen and (min-width: 800px){
  .LandingPageCommunicationBlock .LogoIcon {
    height:     120vh;
    right:      -56vh;
    top:        -10vh;
  }
}

@media all and (-ms-high-contrast:none),(-ms-high-contrast:active) {
  .LandingPage {
    height:           100%;
  }
}

.UtilityBlock {
  display:          flex;
  flex:              1;
  align-items:      center;
  justify-content:  center;
}

.LandingPageLogin {
  position:          absolute;
  left:              0;
  top:              20px;
  width:            100%;
}

.LandingPageSignUpBlock {
  max-width: 350px;
}

.LandingPageSignUpHeader {
  -moz-box-align:     center;
  -ms-flex-align:     center;
  align-items:        center;
  display:            -moz-box;
  display:            -ms-flexbox;
  display:            flex;
  -moz-box-pack:      justify;
  -ms-flex-pack:      justify;
  justify-content:    space-between;
  margin:             16px auto;
}

.LandingPageSignUpTitle {
  font-size:      27px;
  line-height:    32px;
}

.LandingPageLogin .LoginForm {
  display:            -moz-box;
  display:            -ms-flexbox;
  display:            flex;
  -moz-box-orient:    horizontal;
  -moz-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction:     row;
  -moz-box-pack:      center;
  -ms-flex-pack:      center;
  justify-content:    center;
  margin:             0 auto;
  max-width:          600px;
  padding-left:       20px;
  padding-right:      20px;
}

@media only screen and (max-width: 1023px), screen and (max-height: 660px){
  .LandingPageLogin {
    display: none;
  }
}

.LandingPage .Footer {
  position:         static;
}

.LandingPage .FooterList {
  background:       #fff;
  display:          -moz-box;
  display:          -ms-flexbox;
  display:          flex;
  -ms-flex-wrap:    wrap;
  flex-wrap:        wrap;
  -moz-box-pack:    center;
  -ms-flex-pack:    center;
  justify-content:  center;
  width:            auto;
}

.LandingPageSideButtton {
  display:          none;
}

@media only screen and (max-width: 1023px), screen and (max-height: 660px){
  .LandingPageSideButtton {
    display:        inline-block;
  }
}

.LandingPageSelectionArea .LandingPageSelectionAreaSubtitle {
    margin-top:     48px;
}

.LandingPageSelectionAreaSubtitle {
  color:            #14171a;
  font-size:        18px;
  line-height:      24px;
  margin:           16px auto;
}

.LandingPageSignUpButton, .LandingPageLoginButton {
  display:          block;
  margin-bottom:    16px;
  width:            100%;
}

.SideButtonPrimary{
  background-color: orange;
  border:           1px solid orange;
  color:             #fff;
}

.SideButtonPrimary:hover{
  background-color: #dd9003;
  color:            #fff;
}

.SideButtonSecondary {
  background-color: #fff;
  border:           1px solid orange;
  color:            orange;
}

.SideButtonSecondary:hover {
  background-color:  #ffe4b3;
  color:            #fff;
}