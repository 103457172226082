html {
    font-family: sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%
}

body {
    margin: 0
}


svg:not(:root) {
	overflow: 				hidden;
}

body {
	font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif;
}



li {
	text-align: 			inherit;
}

ul, ol {
	margin:						0;
	list-style:				none;
	padding:					0;
}

.uClearFloat:before, .uClearFloat:after {
	content:					" ";
	display:					table;
}

.uClearFloat:after {
	clear:						both;
}

.uClearFloat:before, .uClearFloat:after {
	content:					" ";
	display:					table;
}


.SideButton:focus {
  box-shadow:       0px 0px 10px orange;
  outline:          0;
}

.SideButtonDisabled {
  background-color:  #eee;
  color:            #fff;
}

.SideButtonDisabled:hover {
	
}

.SideButton, SideButton:visited {
  border-radius:    100px;
  box-shadow:       none;
  cursor:           pointer;
  font-size:        14px;
  font-weight:      bold;
  line-height:      20px;
  padding:          6px 16px;
  position:         relative;
  text-align:       center;
  white-space:      nowrap;
}

.SideButtonPrimary{
  background-color: orange;
  border:           1px solid orange;
  color:             #fff;
}

.SideButtonPrimary:hover{
  background-color: #dd9003;
  color:            #fff;
}

.SideButtonSecondary {
  background-color: #fff;
  border:           1px solid orange;
  color:            orange;
}

.SideButtonSecondary:hover {
  background-color:  #ffe4b3;
  color:            #fff;
}

.WaitingScreen {
  display:          flex;
  flex-direction:   column;
  flex:             0.5;
  justify-content:  space-between;
  margin-top:       100px;
  align-items:      center;
  font-size:        19px;
  font-weight:      500;
}

.WaitingScreenSpinnerContainer {
    
}