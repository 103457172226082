.SignupPage {
  width:      100vw;
  height:      100vh;
  background-color:    #777;
}

.SignupPageFormContainer {
  max-width:      600px;
  min-height:     500px;
  margin:         auto;
  display:        flex;
  flex-direction: column;
}
.SignupPageFormBody {
  height:           100%;
  width:            100%;
  display:          flex;
  flex-direction:   column;
  flex:             1;
  align-items:      center;
  justify-content:  space-between;
}

.SignupPageForm {
  width:      70%;
  margin:  10px auto;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif;
}

.SignupHeader {
  position:           relative;
  display:            flex;
  flex-direction:     row;
  align-items:        center;
  justify-content:    space-between;
  margin:             10px;
  min-height:         36px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif;
}

.headerIcon {
  position:       absolute;
  width:          31px;
  height:         34px;
  right:          -82px;
  bottom:         -7px;
}

.SignupHeaderBackContainer {

}

.SignupHeaderNextContainer {

}

.SignupHeaderLabelContainer {
  position:absolute;
  right:          61%;
  font-weight:    800;
  font-size:      19px;
}

.SignupPageFormTitle {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif;
  font-weight:    800;
  font-size:      23px;
  margin:         10px;
}

.SideButtonPrimary{
  background-color: orange;
  border:           1px solid orange;
  color:             #fff;
}

.SideButtonPrimary:hover{
  background-color: #dd9003;
  color:            #fff;
}

.dateOfBirthTitleGroup {
  display:          flex;
  margin:           10px 0px;

}

.dateOfBirthTitle {
  font-weight:      700;
}

.dateOfBirthCaution {
  font-weight:      500;
}

.secretMessage {
  font-size:        10px;
}

.error .label{
  position:       absolute !important;
  z-index:        1000;
}

.displayNone {
  display: none;
}

.FinalFormContainer {
  font-size:      23px;
  font-weight:    500;
  width:          400px;
  margin:         10px auto;
}

.FinalFormItem {
  height:         50px;
  margin:         20px 0px;
}